import React from "react"
import Layout from '../components/Layout'
import ImagesLoader from '../components/ImagesLoader'

export default function Retina() {
  
  return (
    <div>
      <Layout>
      <div className=' w-90-l w-90-m w-100 mw9 center'>
        <div className='flex-l justify-around-l items-center-l w-100 pb4'>  
              <ImagesLoader filename={`retinal-imaging`} className='w-50-l w-60-m w-80 ma3-l ma2 center br1 shadow-1'/>
              <div className="w-60-l pa4-m w-100 black-70 center f4-l f4-m f5">
              <h3 className="ma3-l ma2 pa3 f3 fw7 ttu tracked lh-title mb3 avenir bb">Digital Retina Imaging</h3>
                <p className=" lh-copy ma3-l ma2 pa3">
                  This technology allows optometrists to view the back of the eye in extraordinary detail. We can see the development of many eye diseases when they are in their infancy.   
                  The high resolution camera makes it easy to diagnose many eye diseases including glaucoma, age related macular degeneration, retinal tears and detachments. <br/><br/>
                  Retinal photography is also effective for detecting other diseases.  Often we can see signs of high blood pressure or diabetes before they are even on our patients radar.
                  When it comes to eye diseases, early detection and treatment is key.  It provides a more thorough and permanent record of the health of your eyes for future comparisons.
                </p>
              </div>
        </div>

        <div className='flex-l flex-row-l justify-around-l items-center-l flex flex-column-reverse w-100 pb4'>
              <div className="w-60-l pa4-m w-100 black-70 center f4-l f4-m f5">
              <h3 className="pa3 ma3-l ma2 f3 fw7 ttu tracked lh-title mt0 mb3 avenir bb">OCT imaging</h3>
                <p className=" lh-copy ma3-l ma2 pa3">
                  This technology allows optometrists to view the back of the eye in extraordinary detail. We can see the development of many eye diseases when they are in their infancy.   
                  The high resolution camera makes it easy to diagnose many eye diseases including glaucoma, age related macular degeneration, retinal tears and detachments.
                  <br/><br/>
                  Retinal photography is also effective for detecting other diseases.  Often we can see signs of high blood pressure or diabetes before they are even on our patients radar.
                  When it comes to eye diseases, early detection and treatment is key.  It provides a more thorough and permanent record of the health of your eyes for future comparisons.
                </p>
              </div>
              <ImagesLoader filename={`oct-imaging`} className='w-50-l w-90 pb5-l ma5-l mt0-l ma2 f5 center br1 shadow-1'/>
        </div>
      </div>
      </Layout>
    </div>
    )
}



//<img src="https://www.eyeconx.net/drwiner/files/pic2.png" className="w-100 f5 measure" alt="Eye"/>
             
//<img src="https://www.eyeconx.net/drwiner/files/pic1.png" className="w-100 f5 measure" alt="Retina"/>
